export const FETCH_MENU = "menu/fetch";
export const FETCH_MENU_ERROR = "menu/error";
export const FETCH_MENU_LOADING = "menu/fetch/loading";
export const RESET_MENU = "menu/reset";
export const FETCH_SUGGESTION_MENU = "suggestion/fetch";
export const FETCH_SUGGESTION_MENU_ERROR = "suggestion/error";
export const FETCH_SUGGESTION_MENU_SUCCESS = "suggestion/success";
export const REMOVE_SUGGESTION_MENU = "suggestion/remove";
export const GET_CATEGORY_VALUE = "GET_CATEGORY_VALUE";
export interface CategoryInterface {
	id: number;
	name: string;
}

export interface SubCategoryInterface {
	id: number;
	name: string;
	products: Array<ProductInterface>;
}

export interface ModifierPivotInterface {
	price: number;
	force_tax: number;
}

export interface ModifierInterface {
	id: number;
	name: string;
	pivot: ModifierPivotInterface;
}

export interface GroupInterface {
	id: number;
	name: string;
	modifiers: Array<ModifierInterface>;
}

export interface ProductInterface {
  max_price: number;
  min_price: number;
	id: number;
	title: string;
	description: string;
	image: string;
	price: number;
	tax_exempt: number;
	waiting_time: number;
	groups: Array<GroupInterface> | [];
}

export interface MenuInterface {
	id: number;
	name: string;
	products: Array<ProductInterface>;
	sub_categories: Array<SubCategoryInterface>;
}

export interface MerchantInterface {
	popid_status: any;
	id: number;
	address: string;
	address2: string;
	description: string;
	name: string;
	phone: string;
	photo1: string;
	photo2: string;
	photo3: string;
	photo4: string;
	postal_code: string;
	price: string;
	rating: number;
	tax: number;
	logo: string;
	colors: Array<string>;
	menu: Array<MenuInterface>;
	sightcorp: number;
}

//reducer interface
export interface MerchantState {
	merchant: MerchantInterface;
	promotions: Array<string>;
	colors: Array<string>;
	loading: boolean;
	suggestions: Array<ProductInterface>;
	loadingSuggestions: boolean;
	valueCategory: number;
}

interface FetchValueCategoryAction {
	type: typeof GET_CATEGORY_VALUE;
	valueCategory: number;
}

// fetch menu action
interface FetchMenuAction {
	type: typeof FETCH_MENU | typeof FETCH_MENU_ERROR;
	payload: {
		merchant: MerchantInterface;
		promotions: Array<string>;
		colors: Array<string>;
		loading: boolean;
	};
}
// fetch menu action (loading)
interface FetchMenuLoadingAction {
	type: typeof FETCH_MENU_LOADING;
}

interface ResetMenuAction {
	type: typeof RESET_MENU;
}

interface RemoveSuggestionAction {
	type: typeof REMOVE_SUGGESTION_MENU;
}

interface SuggestionsAction {
	type:
		| typeof FETCH_SUGGESTION_MENU
		| typeof FETCH_SUGGESTION_MENU_ERROR
		| typeof FETCH_SUGGESTION_MENU_SUCCESS;
	payload: {
		suggestions: Array<ProductInterface>;
	};
}

export type MenuActionTypes =
	| FetchMenuAction
	| FetchMenuLoadingAction
	| FetchValueCategoryAction;
export type ResetMenuTypes = ResetMenuAction;
export type SuggestionsTypes = SuggestionsAction | RemoveSuggestionAction;
