import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Grid, Button, Typography } from "@mui/material";
import { useStyles } from "./styles";
import { RootState } from "../../store/rootReducer";
import { PATHS } from "../../routes/paths";
import { history } from "../../routes/history";
import useWebSocket from "react-use-websocket";
import avatar from "../../assets/img/avatar.png";
import { useDispatch, useSelector } from "react-redux";
import PopIdDialog from "../../components/popIdDialog";
import {
	POP_ID,
	SEND_MSG_POPID_TYPE_SEARCH,
	VIDEO_PREVIEW_ACCESS_PORT,
	WEBSOCKET_CONNECTION_PORT
} from "../../utils/constants";
import { passOrder, stripeOrPopId } from "../../store/order/actions";
import { getRealPerson } from "../../store/popID/action";

export const PopIdPaymentPage = () => {
	const { t } = useTranslation();
	const menuState = useSelector((state: RootState) => state.menu);
	const ShoppingCartState = useSelector(
		(state: RootState) => state.shoopingCart
	);
	const colors = menuState?.colors;
	const classes = useStyles({ colors });
	const [canCancel, setCanCancel] = useState(true);
	const [image, setImage] = useState("");
	const [ready, setImReady] = useState(false);
	const [changeText, setChangeText] = useState(false);
	const [hideButton, setHideButton] = useState(true);
	const [responsePopId, setResponsePopId] = useState("");
	const [openDialog, setOpenDialog] = useState(false);
	const [detectedPerson, setDetectedPerson] = useState([]);
	const shoopingCart = useSelector((state: RootState) => state.shoopingCart);
	const popIdUrl = localStorage.getItem(POP_ID);
	const dispatch = useDispatch();

	const closeDialog = () => {
		setOpenDialog(false);
		history.push(PATHS.Home);
	};
	const handleTrayAgain = () => {
		setChangeText(false);
		setImReady(false);
		setOpenDialog(false);
		setHideButton(true);
	};
	const handleRedirectStrip = () => {
		const popIdStatus: any = 0;
		dispatch(stripeOrPopId(popIdStatus));
		dispatch(passOrder(shoopingCart, popIdStatus));
		history.push(PATHS.PAYMENT);
	};
	useWebSocket(
		popIdUrl + VIDEO_PREVIEW_ACCESS_PORT,
		{
			onMessage: (event: any["message"]) => {
				setImage(event.data);
			}
		},
		ready
	);

	const { sendMessage } = useWebSocket(
		popIdUrl + WEBSOCKET_CONNECTION_PORT,
		{
			onOpen: () => {
				sendMessage(SEND_MSG_POPID_TYPE_SEARCH);
			},
			onClose: () => {},
			shouldReconnect: closeEvent => true,
			onMessage: (event: WebSocketEventMap["message"]) => {
				processMessages(event);
			}
		},
		ready
	);

	const processMessages = (event: { data: string }) => {
		const response = JSON.parse(event.data);
		if (response.error) {
			setResponsePopId(response.error);
			setOpenDialog(true);
		} else {
			setResponsePopId("");
		}
		response.length != null
			? setDetectedPerson(response)
			: setDetectedPerson([]);
	};
	const totalValue = () => {
		return ShoppingCartState.shoopingProduct.reduce(
			(somme, current) => somme + current.price,
			0
		);
	};
	const goBack = () => {
		if (canCancel) {
			history.push(PATHS.CHOICE_MODE_PAYMENT);
		}
	};
	const takePicture = () => {
		setImReady(true);
		setChangeText(true);
	};
	useEffect(() => {
		if (ready && changeText) {
			setHideButton(false);
		}
	}, [ready, changeText]);

	useEffect(() => {
		if (detectedPerson.length > 0) {
			history.push(PATHS.CHECKOUT);
			dispatch(getRealPerson(detectedPerson));
		}
	}, [detectedPerson]);
	return (
		<Grid container direction="column" className={classes.container}>
			<Grid container justifyContent="flex-start">
				<Button
					className={classes.button}
					onClick={() => goBack()}
					disabled={!canCancel}
				>
					{t("common.back")}
				</Button>
			</Grid>
			<Grid container direction="column" className={classes.content}>
				<Grid>
					<Typography className={classes.loaderText}>
						{t("payment.total")}
					</Typography>
					<Typography variant="h3" className={classes.price}>
						$
						{(
							totalValue() +
							ShoppingCartState.totalTax +
							ShoppingCartState.tipToPay
						).toFixed(2) || 0}
					</Typography>
					<Typography variant="h5" style={{ color: colors[0] }}>
						{changeText
							? t("payment.one_moment")
							: t("payment.scan_face")}
					</Typography>
					<Typography variant="h5" style={{ color: colors[0] }}>
						{changeText
							? t("payment.processing_face_scan")
							: t("payment.remove_mask_sunglasses_hat")}
					</Typography>
				</Grid>
				<Grid
					style={{
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignContent: "center",
						alignItems: " center",
						rowGap: "20px"
					}}
				>
					<img
						src={
							image && ready
								? `data:image/png;base64,` + image
								: avatar
						}
						alt="Red dot"
						className={classes.img}
					/>
					{hideButton ? 
						<Button
							onClick={takePicture}
							style={{
								backgroundColor: colors[0],
								color: colors[1],
								height: 50,
								width: 200
							}}
						>
							{t("payment.ready")}
						</Button>
					: <div style={{height: 50, width: 200}}></div>}
				</Grid>
				<PopIdDialog
					open={openDialog}
					pay={false}
					handleClose={closeDialog}
					handleRedirectStrip={handleRedirectStrip}
					handleTrayAgain={handleTrayAgain}
					message={responsePopId}
				></PopIdDialog>
			</Grid>
		</Grid>
	);
};

export default PopIdPaymentPage;
