/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import "../../assets/sass/common.scss";
import { Button, Card, CardContent, Grid, Typography } from "@mui/material";
import placeHolder from "../../assets/img/placeholder.png";
import { useStyles } from "./styles";
import { QunatityButtons } from "../../components";
import { useTranslation } from "react-i18next";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import ArrowBackIos from "@mui/icons-material/ArrowBackIos";
import { ModifierCheckBox } from "../../components/ModifierCheckBox";
import {
	LOCAL_STORAGE_CATEGORY,
	LOCAL_STORAGE_PRODUCT,
	SEPARATED_MIN_MAX_PRODUCT
} from "../../utils/constants";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/rootReducer";
import { config } from "../../config/config";
import { history } from "../../routes/history";
import { PATHS } from "../../routes/paths";
import { AddProductToShoopingCart } from "../../store/shoopingCart/actions";
import { CheckIsGlobal, getPriceFormat } from "../../utils/helpers";
import { TakeInOutDialog } from "../../components";
import { useDialog } from "../../hooks/useDialog";
import CardMedia from "../../components/CardMedia";
import ConfirmDialog from "../../components/ConfirmDialog";
import { Button as Btn } from '../../components/Button';
import { INCREMENT_VALUE } from  '../../utils/constants'
interface ProductDetailsProps {}

export const ProductDetails = (props: ProductDetailsProps) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const product = JSON.parse(
		localStorage.getItem(LOCAL_STORAGE_PRODUCT) || "{}"
	);
	const category = JSON.parse(
		localStorage.getItem(LOCAL_STORAGE_CATEGORY) || "{}"
	);
	const menuState = useSelector((state: RootState) => state.menu);
	const colors = menuState?.colors;
	const dialogLogout = useDialog();

	const shoopingCart = useSelector((state: RootState) => state.shoopingCart);

	const [quantity, setQunatity] = React.useState<number>(1);
	const [modifiersTotalPrice, setModifiersTotalPrice] =
		React.useState<number>(0);

	const [modifiers, setModifiers] = React.useState<Array<any>>([]);
	const [openDialog, setOpenDialog] = React.useState<boolean>(false);
	const [message, setMessage] = React.useState<string>("");
	const [count, setCount] = React.useState<number>(0);
	const [groupsNames, setGroupsNames] = React.useState<Array<string> | string>([]);
	const [list, setList] = React.useState<Array<string>>([]);
	const [foundModifiersByGroupName, setFoundModifiersByGroupName] = React.useState<any>([]);
	const [dialog, setDialog] = React.useState<boolean>(false);
	const [variation, setVariation] = React.useState<boolean>(false);
	const closeDialog = () => {
		setOpenDialog(false);
	};
	useEffect(() => {
		const varationProduct = product.groups.filter((grp: any) => grp.variation === 1)
	  varationProduct.length !== 0 && setVariation(true)	}, []);
	
	const handleOnIncreaseBtnClick = () => {
		setQunatity(quantity + 1);
	};

	const handleonDecreaseBtnClick = () => {
		if (quantity > 1) {
			setQunatity(quantity - 1);
		}
	};

	const handlePrevModifier = () => {
		if (count > 0 && count <= list.length) {
			setCount(count - 1);
		}
	}
	const handleNextModifier = () => {	

			if (count < list.length - 1 && count >= 0 && dialog) {
				setOpenDialog(true);
				setMessage(
					`The modifier ${foundModifiersByGroupName.name} is required`
				);
			} else {
           setCount(count + 1);
			}
	};
useEffect(() => {
	if (
		foundModifiersByGroupName?.required == 1 &&
		modifiers.filter(m => m.group_id == foundModifiersByGroupName.id)
			.length == 0
	) {
		setDialog(true)
	} else setDialog(false)

}, [modifiers, foundModifiersByGroupName, count]);
	
	const handleSelectModifier = (
		event: any,
		modifier: any,
		group: any
	) => {

		if (modifiers.findIndex(e => e.modifier_id === modifier.id) !== -1) {
			const mod = modifiers.filter(e => e.modifier_id !== modifier.id);
			setModifiers([...mod]);
		} else {
			if (group.multiple === 1) {
				let modif = modifiers.filter(el => el.group_id == group.id);

				if (modif.length < group.max_modifiers) {
					setModifiers([
						...modifiers,
						{
							modifier_id: modifier.id,
							group_id: group.id,
							price: modifier.pivot.price
						}
					]);
				}
			} else {
				if (modifiers.findIndex(e => e.group_id === group.id) !== -1) {
					const mod = modifiers.filter(
						e => e.group_id !== modifier.pivot.group_id
					);
					setModifiers([
						...mod,
						{
							modifier_id: modifier.id,
							group_id: group.id,
							price: modifier.pivot.price
						}
					]);
				} else {
					setModifiers([
						...modifiers,
						{
							modifier_id: modifier.id,
							group_id: group.id,
							price: modifier.pivot.price
						}
					]);
				}
			}
		}
	}

	useEffect(() => {
		setGroupsNames(list[count]);
		setFoundModifiersByGroupName(
			product.groups.find(g => g.name == list[count])
		);
	}, [count]);

	useEffect(() => {
		const listgroupsNames: string[] = [];
		{
			product.groups &&
				product.groups.map((group: any) =>
					listgroupsNames.push(group.name)
				);
			setList(listgroupsNames);
		}
		setGroupsNames(listgroupsNames[0]);
		setFoundModifiersByGroupName(
			product.groups.find(g => g.name == listgroupsNames[0])
		);
	}, []);

	const addAProductToTheShooping = React.useCallback(() => {
		const price = variation
			? +(
					(quantity *
						(
							modifiers
								.map(item => item.price)
								.reduce((prev, curr) => prev + curr, 0))) /
					100
			  ).toFixed(2)
			: +(
					(quantity *
						(product.price +
							modifiers
								.map(item => item.price)
								.reduce((prev, curr) => prev + curr, 0))) /
					100
			  ).toFixed(2);
		const mods = modifiers.map(function (item) {
			return item.modifier_id;
		});

		let requiredGroupsName: string[] = [];
		let requiredGroups = product.groups
			.filter(el => el.required)
			.map(el => {
				return `${el.id}-${el.name}`;
			});

		requiredGroups.map(el => {
			if (
				modifiers.filter(e => e.group_id == el.split("-")[0]).length ==
				0
			) {
				requiredGroupsName.push(el.split("-")[1]);
			}
		});
		if (requiredGroupsName.length == 0) {
			if (CheckIsGlobal() && shoopingCart.shoopingProduct.length === 0) {
				dialogLogout.setOpen(true);
			} else {
				dispatch(
					AddProductToShoopingCart(product, mods, price, quantity)
				);
				history.push(PATHS.Home);
			}
		} else {
			setOpenDialog(true);
			setMessage(`The modifier ${requiredGroupsName[0]} is required`);
		}
	}, [modifiers, product, quantity, modifiersTotalPrice, quantity]);

	const handleSubmit = () => {
		const price = variation
			? +(
					(quantity *
						(
							modifiers
								.map(item => item.price)
								.reduce((prev, curr) => prev + curr, 0))) /
					100
			  ).toFixed(2)
			: +(
					(quantity *
						(product.price +
							modifiers
								.map(item => item.price)
								.reduce((prev, curr) => prev + curr, 0))) /
					100
			  ).toFixed(2);;
		const mods = modifiers.map(function (item) {
			return item.modifier_id;
		});
		dispatch(AddProductToShoopingCart(product, mods, price, quantity));
		dialogLogout.setOpen(false);
		history.push(PATHS.Home);
	};

	React.useEffect(() => {
		document.body.style.backgroundColor = colors[0];
	});

	React.useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const classes = useStyles({ colors: colors });

	const onBackBtnPress = () => {
		history.push(PATHS.Home);
	};

	return (
		<div>
			<AppBar
				position="fixed"
				style={{ backgroundColor: colors[1] }}
				className={classes.appBar}
			>
				<Toolbar variant="dense">
					<IconButton
						onClick={onBackBtnPress}
						edge="start"
						color="inherit"
						aria-label="menu"
						size="large"
					>
						<ArrowBackIos style={{ color: colors[0] }} />
					</IconButton>
					<Typography color="inherit">
						<span
							className={classes.category}
							style={{ color: colors[0] }}
						>
							{category.name}
						</span>
					</Typography>
				</Toolbar>
			</AppBar>
			<Grid
				container
				justifyContent="center"
				alignItems="center"
				className={classes.root}
			>
				<Grid container>
					<Grid
						item
						md={4}
						sm={4}
						xs={10}
						container
						justifyContent="center"
					>
						<div className={classes.imgContainer}>
							<CardMedia
								className={classes.media}
								image={
									product.image &&
									!product.image.startsWith("https")
										? config.BASE_URL_API + product.image
										: product.image &&
										  product.image.startsWith("https")
										? product.image
										: placeHolder
								}
								title={product.title}
							/>
						</div>
					</Grid>
					<Grid
						md={7}
						sm={6}
						xs={10}
						item
						className={classes.titleMainContainer}
					>
						<div className={classes.titleContainer}>
							<div className={classes.titleAndPrice}>
								<Grid item xs={6}>
									<div
										className={classes.title}
										style={{ color: colors[1] }}
									>
										{product.title}
									</div>
								</Grid>
								<Grid item xs={6}>
									<div
										className={classes.price}
										style={{ color: colors[1] }}
									>
										{/* {product.price
											? (product.price / 100).toFixed(2)
											: "00.00"} */}
										{variation ? (
											<>
												$
												{getPriceFormat(
													product.min_price / 100
												) +
													SEPARATED_MIN_MAX_PRODUCT +
													getPriceFormat(
														product.max_price / 100
													)}
											</>
										) : product.price ? (
											<>
												$
												{(product.price / 100).toFixed(
													2
												)}
											</>
										) : (
											"00.00"
										)}
									</div>
								</Grid>
							</div>
							<div
								className={classes.descrption}
								style={{ color: colors[1] }}
							>
								{product.description}
							</div>
						</div>
						<div></div>
					</Grid>
				</Grid>
				<Grid container>
					<div className={classes.qunatityContainer}>
						<Grid item xs={4} />
						<Grid
							xs={7}
							item
							className={classes.qunatityButtonsContainer}
						>
							<div>
								<QunatityButtons
									onDecreaseBtnClick={
										handleonDecreaseBtnClick
									}
									onIncreaseBtnClick={
										handleOnIncreaseBtnClick
									}
									colors={colors}
									quantity={quantity}
								/>
							</div>
						</Grid>
					</div>
				</Grid>
				<Grid container style={{ justifyContent: "flex-end" }}>
					{product.groups.length >= 1 && (
						<div
							style={{
								display: "flex",
								flexDirection: "row",
								width: "100%",
								justifyContent: "center"
							}}
						>
							<div
								style={{
									color: colors[1],
									marginTop: "12px",
									fontSize: "25px",
									width: "90%",
									marginLeft: "10%"
								}}
								// title={groupsNames}
							>
								{groupsNames}
							</div>

							<div
								style={{
									color: colors[1],
									margin: "35px -2% 10px 10px",
									width: "10%"
								}}
							>
								{product.groups.findIndex((object: any) => {
									return object.name === groupsNames;
								}) +
									INCREMENT_VALUE +
									"/" +
									product.groups.length}
							</div>
						</div>
					)}
				</Grid>

				<Grid container className={classes.modContainer}>
					<div
						style={{
							minWidth: "100%",
							marginBottom: "150px"
						}}
					>
						<div>
							{product.groups.length >= 1 && (
								<Card
									style={{
										display: "flex",
										flexDirection: "column",
										alignItems: "center",
										border: "2px solid " + colors[1]
									}}
								>
									<div
										style={{
											width: "100%",
											height: "100%",
											overflow: "scroll",
											maxHeight: "250px"
										}}
									>
										<CardContent
											style={{
												display: "flex",
												flexWrap: "wrap",
												justifyContent: "center"
											}}
										>
											{foundModifiersByGroupName &&
												foundModifiersByGroupName
													?.modifiers?.length >= 1 &&
												foundModifiersByGroupName.modifiers.map(
													m => (
														<Card
															style={{
																flex: "1 0 21",
																margin: "1%",
																height: "70px",
																minWidth:
																	"290px"
															}}
														>
															<div>
																<div
																	style={{
																		color: colors[1],
																		marginRight: 22,
																		marginLeft: 22,
																		marginTop:
																			"3%"
																	}}
																>
																	{m.name}
																</div>
															</div>

															<div
																className={
																	classes.checkPrice
																}
															>
																<div>
																	<ModifierCheckBox
																		checked={
																			modifiers.findIndex(
																				e =>
																					e.modifier_id ===
																					m.id
																			) !=
																			-1
																		}
																		onChange={event => {
																			handleSelectModifier(
																				event,
																				m,
																				foundModifiersByGroupName
																			);
																		}}
																		data-price={
																			m.price
																		}
																		value={
																			m.id
																		}
																	/>
																</div>
																<div
																	style={{
																		marginTop:
																			"4%",
																		marginRight:
																			"8%"
																	}}
																>
																	<span
																		style={{
																			color: colors[1]
																		}}
																	>
																		$
																		{(
																			m
																				.pivot
																				.price /
																			100
																		).toFixed(
																			2
																		)}
																	</span>
																</div>
															</div>
														</Card>
													)
												)}
										</CardContent>
									</div>
								</Card>
							)}
						</div>
					</div>

					<div
						style={{
							height: "150px",
							width: "100%",
							bottom: "5%",
							position: "fixed",
							backgroundColor: colors[0]
						}}
					>
						{product.groups.length >= 1 && (
							<div
								style={{
									display: "flex",
									flexDirection: "row",
									justifyContent: "center",
									columnGap: "1%"
								}}
							>
								<div>
									<Btn
										title={t("groupsOfModifiers.Previous")}
										onClick={() => {
											handlePrevModifier();
										}}
										disabled={
											product?.groups[0]?.name ==
											groupsNames
												? true
												: false
										}
										color={colors[1]}
									/>
								</div>
								<div>
									<Btn
										title={t("groupsOfModifiers.Next")}
										onClick={() => {
											handleNextModifier();
										}}
										disabled={
											product?.groups[
												product?.groups.length - 1
											]?.name == groupsNames
												? true
												: false
										}
										color={colors[1]}
									/>
								</div>
							</div>
						)}

						<div className={classes.addToOrderBtnContainer}>
							<Button
								onClick={addAProductToTheShooping}
								style={{
									backgroundColor: colors[1],
									color: colors[0],
									height: 50,
									width: 200
								}}
							>
								{t("productDetail.add_to_order")}$
								{variation
									? (
											(quantity *
												modifiers
													.map(item => item.price)
													.reduce(
														(prev, curr) =>
															prev + curr,
														0
													)) /
											100
									  ).toFixed(2)
									: (
											(quantity *
												(product.price +
													modifiers
														.map(item => item.price)
														.reduce(
															(prev, curr) =>
																prev + curr,
															0
														))) /
											100
									  ).toFixed(2)}
							</Button>
						</div>
					</div>

					{/* {product.groups &&
						product.groups.map((group: any, index: number) => (
							<div
								className={classes.modContainer}
								key={"product-" + index}
							>
								<div className={classes.modifierGroupContainer}>
									<div
										className={classes.span}
										style={{ color: colors[1] }}
									>
										{group.name}
									</div>
									{group.modifiers &&
										group.modifiers.map(
											(
												modifier: any,
												modifierIndex: number
											) => (
												<div
													className={
														classes.modifiers
													}
													key={
														"modifier-" +
														modifierIndex
													}
												>
													<div
														className={
															classes.modifier
														}
													>
														<Grid
															item
															xs={4}
															className={
																classes.modifierName
															}
														>
															<span
																style={{
																	color: colors[1]
																}}
															>
																{modifier.name}
															</span>
														</Grid>
														<Grid item xs={7}>
															<div
																className={
																	classes.checkboxContainer
																}
															>
																<span
																	style={{
																		color: colors[1]
																	}}
																>
																	$
																	{(
																		modifier
																			.pivot
																			.price /
																		100
																	).toFixed(
																		2
																	)}
																</span>
																<ModifierCheckBox
																	checked={
																		modifiers.findIndex(
																			e =>
																				e.modifier_id ===
																				modifier.id
																		) != -1
																	}
																	onChange={event => {
																		handleSelectModifier(
																			event,
																			modifier,
																			group
																		);
																	}}
																	data-price={
																		modifier.price
																	}
																	value={
																		modifier.id
																	}
																/>
															</div>
														</Grid>
													</div>
												</div>
											)
										)}
								</div>
							</div>
						))} */}
				</Grid>
			</Grid>
			<TakeInOutDialog
				open={dialogLogout.open}
				handleClose={() => {
					dialogLogout.setOpen(false);
				}}
				onBackdropClick={() => {
					dialogLogout.setOpen(true);
				}}
				handleSubmit={handleSubmit}
			/>
			<ConfirmDialog
				handleClose={closeDialog}
				open={openDialog}
				message={message}
			/>
		</div>
	);
};
export default ProductDetails;
